import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { EnrolmentComponent } from './enrolment/enrolment.component';
import { ReportsComponent } from './reports/reports.component';
import { SearchreservationComponent } from './searchreservation/searchreservation.component';
import { VersionComponent } from './version/version.component';

const routes: Routes = [{ path: '', component: ProfileComponent },
{ path: 'enrolment', component: EnrolmentComponent },
{ path: 'reports', component: ReportsComponent },
{path:'searchreservation',component:SearchreservationComponent},
{path:'version',component:VersionComponent},
// otherwise redirect to home
{ path: '**', redirectTo: '' }];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
