import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberService } from 'src/services';
import { MemberEnrollService } from 'src/services/memberenroll.service';

@Component({
  selector: 'app-searchreservation',
  templateUrl: './searchreservation.component.html',
  styleUrls: ['./searchreservation.component.less']
})
export class SearchreservationComponent implements OnInit {

  constructor(
    private memberEnrollService:MemberEnrollService,
    private activatedRoute:ActivatedRoute,
    private memberService:MemberService,
    private router:Router) { }
  reservationSearchTerm:any;
  profileIdSearchTerm:any;
  selectedHotel:any;
  property_id:any;
  showError:boolean=false;
  gMemberDetails: any;
  showProfileError: any = false;
  errmsg: any = '';

  ngOnInit() {
    this.property_id =  this.activatedRoute.snapshot.queryParams['p'];
    this.selectedHotel = this.memberService.hotelSSOlogindetails;
  }
  searchForReservations(){
    this.showError=false;
    this.memberEnrollService.getReservationTokenData(this.property_id,this.reservationSearchTerm).then((data:any)=>{
      if(data && data.confirmation_id){
        localStorage.setItem('reservationDetails',JSON.stringify(data));
        this.router.navigate(['/'],{queryParams:{p:this.property_id,r:data.confirmation_id}});
      }
    }).catch((error)=>{
      this.showError =true;
    });
  }

  searchForProfileId(){
    this.showProfileError = false;
    this.memberService
    .getMemberData(this.profileIdSearchTerm, this.property_id)
    .then((data: any) => {
      this.gMemberDetails = data;
      console.log('this.gMemberDetails',this.gMemberDetails);
      localStorage.setItem('gMemberDetails',JSON.stringify(this.gMemberDetails));
      if(data.data.membership_stage == "Non-member") {
        this.router.navigate(['/enrolment'],{queryParams:{g:this.profileIdSearchTerm,p:this.property_id}});
        return;
      } else if(data.data.membership_stage != "Non-member") {
        this.router.navigate(['/'],{queryParams:{g:this.profileIdSearchTerm,p:this.property_id}});
      }
    })
    .catch(error => {
      this.showProfileError = true;
      if(error.error.error.code == 'SEP_Authentication_Failed') {
        this.errmsg = 'Session expired. Close window and re-login. Contact admin for further support';
      } else if(error.error.error.code == 'NON_MEMBER_NOT_FOUND') { 
        this.errmsg = 'Please ensure Profile has consented'
      } else {
        this.errmsg = 'Profile not found';
      }
      console.log('errorrrr',error);
      localStorage.setItem('gMemberDetails', null);
      if(error === 'API not found'){
        // this.getpmsData(this.profileId, this.resort);
        return;
      }
      if(!error.error)
        return;
    });
    // this.router.navigate(['/enrolment'],{queryParams:{g:this.profileIdSearchTerm,p:this.property_id}});
  }

}
