import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { WebService } from "../services/web.service";
import { AuthenticationService, CommonService } from '../services';
import { LocationStrategy } from '@angular/common';
import { environment } from "./../environments/environment";
import {timer} from 'rxjs';
import {take} from 'rxjs/operators'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'Jumeirah GIS';
  language = "en_US";
  constructor(
    private webService: WebService,
  	private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private location: LocationStrategy
  ) {
    translate.setDefaultLang(this.language);
    this.location.onPopState(() => {
      this.commonService.setBackClicked(true);
      return false;
    });
  }
  private gravtyhost = environment.gravtyhost();
  private host = environment.host();
  private username = environment.username;
  private password = environment.password;
  switchLanguage(language) {
    let lang;
    if (language === undefined || language === "") {
      lang = navigator.language;
    } else {
      lang = language;
    }
    if (lang.indexOf("-") !== -1) {
      lang = lang.split("-")[0];
    }
    if (lang.indexOf("_") !== -1) {
      lang = lang.split("_")[0];
    }
    switch (lang) {
      case "en":
        lang = "en_US";
        break;
      case "es":
        lang = "es_ES";
        break;
      case "fr":
        lang = "fr_FR";
        break;
      case "pt":
        lang = "pt_BR";
        break;
      case "ar":
        lang = "ar_AE";
        break;
      default:
        lang = "en_US";
        break;
    }
    this.translate.use(lang);
  }
  setExpirationTime() {
    // localStorage.removeItem('tokenTimeOut');
    // localStorage.removeItem('token');
    // var req: any ={
    //   password: this.password,
    //   username: this.username
    // }
    // var requestUrl = this.gravtyhost + 'login/';
    // this.webService.postAPI(requestUrl, req, false).then((data: any) => {
      // localStorage.setItem('token',data.token);
      var regName
      if(localStorage.getItem('region')) {
        regName = localStorage.getItem('region');
      } else {
        regName = 'eu1';
      }
      localStorage.clear();
      var url= window.location.href;
      var path = url.split('/#/');
      var redirectUrl = path[0] + '/#/?region=' + regName;
      window.open(redirectUrl);
      var loginTime = new Date().getTime();
      var tokenExpiry = loginTime + (3600)*1000;
      localStorage.setItem('tokenTimeOut',tokenExpiry.toString());
    // });
    // var tenantInfoUrl = this.gravtyhost + 'tenants/basic-info/';
    // this.webService.getAPI(tenantInfoUrl, false).then((data: any) => {
    //   localStorage.setItem('tenantInfo',JSON.stringify(data));
    // });
    
  }
  interval: any;
  expirationCheck() {
    var currentTime = new Date().getTime();
    var tokentimeout;
    if(localStorage.getItem('tokenTimeOut')==undefined || localStorage.getItem('tokenTimeOut')==null){
      this.setExpirationTime();
      return;
    }
    else{
      tokentimeout = parseInt(localStorage.getItem('tokenTimeOut'));
    }
    if (tokentimeout < currentTime) {
      this.setExpirationTime();
    }
  }

  ngOnInit() {
    timer(0, 300000).pipe().subscribe(x=>{
      this.expirationCheck();
    })
    this.switchLanguage(this.language);
  }
}
