import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: "root"
})
export class WebService {
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }
    getAPI(requestUrl, status) {
        let reauthentication = localStorage.getItem('reauthentication');
        if (reauthentication && reauthentication !== null) {
            reauthentication = JSON.parse(reauthentication);
        }
        let promise = new Promise((resolve, reject) => {
            this.http
                .get(requestUrl, { observe: "response" })
                .toPromise()
                .then(data => {
                    if (status) {
                        resolve(data);
                    } else {
                        resolve(data.body);
                    }
                })
                .catch(error => {
                    if (error.status === 404) {
                        error = "API not found";
                    } else if (error.status === 401) {
                        if(!reauthentication){
                            this.authenticationService.logout();
                        }
                    } else if (error.status === 500) {
                        error = "Internal Server error";
                    } else if (error.status === 504) {
                        error = "API not found";
                    } else if (error.status === 502) {
                        error = "API not found";
                    } else if (error.status === 506) {
                        error = "API not found";
                    }
                    reject(error);
                });
        });
        return promise;
    }

    postAPI(requestUrl, request, status) {
        let promise = new Promise((resolve, reject) => {
            this.http
                .post(requestUrl, request, { observe: "response" })
                .toPromise()
                .then(data => {
                    if (status) {
                        resolve(data);
                    } else {
                        resolve(data.body);
                    }
                })
                .catch(error => {
                    if (error.status === 404) {
                        error = "API not found";
                    } else if (error.status === 500) {
                        error = "Internal Server error";
                    } else if (error.status === 504) {
                        error = "API not found";
                    } else if (error.status === 502) {
                        error = "Bad Gateway";
                    } else if (error.status === 506) {
                        error = "API not found";
                    }
                    reject(error.error);
                });
        });
        return promise;
    }
    putAPI(requestUrl, request, status) {
        let promise = new Promise((resolve, reject) => {
            this.http
                .put(requestUrl, request, { observe: "response" })
                .toPromise()
                .then(data => {
                    if (status) {
                        resolve(data);
                    } else {
                        resolve(data.body);
                    }
                })
                .catch(error => {
                    if (error.status === 404) {
                        error = "API not found";
                    } else if (error.status === 500) {
                        error = "Internal Server error";
                    } else if (error.status === 504) {
                        error = "API not found";
                    } else if (error.status === 502) {
                        error = "API not found";
                    } else if (error.status === 506) {
                        error = "API not found";
                    }
                    reject(error.error);
                });
        });
        return promise;
    }

    deleteAPI(requestUrl, status) {
        let promise = new Promise((resolve, reject) => {
            this.http
                .delete(requestUrl, { observe: "response" })
                .toPromise()
                .then(data => {
                    if (status) {
                        resolve(data);
                    } else {
                        resolve(data.body);
                    }
                })
                .catch(error => {
                    if (error.status === 404) {
                        error = "API not found";
                    } else if (error.status === 500) {
                        error = "Internal Server error";
                    } else if (error.status === 504) {
                        error = "API not found";
                    } else if (error.status === 502) {
                        error = "API not found";
                    } else if (error.status === 506) {
                        error = "API not found";
                    }
                    reject(error.error);
                });
        });
        return promise;
    }

    patchAPI(requestUrl, request, status) {
        let promise = new Promise((resolve, reject) => {
            this.http
                .patch(requestUrl, request, { observe: "response" })
                .toPromise()
                .then(data => {
                    if (status) {
                        resolve(data);
                    } else {
                        resolve(data.body);
                    }
                })
                .catch(error => {
                    if (error.status === 404) {
                        error = "API not found";
                    } else if (error.status === 500) {
                        error = "Internal Server error";
                    } else if (error.status === 504) {
                        error = "API not found";
                    } else if (error.status === 502) {
                        error = "API not found";
                    } else if (error.status === 506) {
                        error = "API not found";
                    }
                    reject(error.error);
                });
        });
        return promise;
    }

    getAPIWithResponseHeaders(requestUrl, status) {
        let reauthentication = localStorage.getItem('reauthentication');
        if (reauthentication && reauthentication !== null) {
            reauthentication = JSON.parse(reauthentication);
        }
        let promise = new Promise((resolve, reject) => {
            this.http
                .get(requestUrl, { observe: "response" })
                .toPromise()
                .then(data => {
                    if (status) {
                        resolve(data);
                    } else {
                        resolve(data);
                    }
                })
                .catch(error => {
                    if (error.status === 404) {
                        error = "API not found";
                    } else if (error.status === 401) {
                        if(!reauthentication){
                            this.authenticationService.logout();
                        }
                    } else if (error.status === 500) {
                        error = "Internal Server error";
                    } else if (error.status === 504) {
                        error = "API not found";
                    } else if (error.status === 502) {
                        error = "API not found";
                    } else if (error.status === 506) {
                        error = "API not found";
                    }
                    reject(error.error);
                });
        });
        return promise;
    }
}