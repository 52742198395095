import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MemberService } from 'src/services';


@Component({
  selector: 'app-hotelistpopup',
  templateUrl: './hotelistpopup.component.html',
  styleUrls: ['./hotelistpopup.component.less']
})

export class HotelistpopupComponent implements OnInit {

  constructor(private memberService:MemberService,private router:Router) { }
  @Input() hotelsList;
  @Output() hotelModalClose = new EventEmitter();
  selectedHotel;
  selectedHotelName;
  hotellistCopy=[];
  ngOnInit() {
    this.selectedHotel =null;
  }
  goToHotelSSOLogin(){
    let selectedRegion = localStorage.getItem('region');
    this.memberService.loginToParticularHotel(this.selectedHotel,selectedRegion).then((hotelsLoginData:any)=>{
      localStorage.setItem('hotel_sso_token',hotelsLoginData.token);
      localStorage.setItem('pms_data',hotelsLoginData.username);
      this.memberService.hotelSSOlogindetails = hotelsLoginData;
      this.hotelModalClose.emit(0);
			this.router.navigate(['/searchreservation'],{queryParams:{p:hotelsLoginData.property_id}}) ;
    }).catch((error)=>{
      // localStorage.removeItem('hotel_sso_token');
    });
  }


  onSearchChange(event){
    this.hotellistCopy=[];
    this.selectedHotel = null;
    for(let i=0;i<this.hotelsList.length;i++){
      if(((this.hotelsList[i].name).toLowerCase()).includes((event.toLowerCase()))){
        this.hotellistCopy.push(this.hotelsList[i]);
      }
    }
  }

  emptySearch(){
    this.selectedHotelName ='';
    this.selectedHotel = null;
    this.hotellistCopy = [];
  }

  hotelClicked(hotel){
    this.selectedHotel = hotel;
    this.hotellistCopy=[];
    this.selectedHotelName = hotel.name;
  }


}



