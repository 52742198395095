import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.less']
})
export class VersionComponent implements OnInit {

  currentVersion: string = "";
  buildDate: any = {};
  buildDateTime: any;
  constructor() { }

  ngOnInit(): void {
    this.currentVersion = "Langham-SEP-1.1.7";
    this.buildDate = new Date('2024-05-28');
    this.buildDateTime = moment(this.buildDate, 'YYYY-MM-DD').format("DD/MM/YYYY");
  }

}