import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AppComponent } from "../app.component";
import { MemberEnrollService } from "../../services/memberenroll.service";
import { WebService } from "../../services/web.service";
import { CommonService, MemberService } from '../../services';
import * as moment from 'moment-timezone';
import countryCode from 'src/models/data.json'
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormsModule,
  NgForm
} from "@angular/forms";
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators'; 
import { FlatpickrOptions } from 'ng2-flatpickr';
@Component({
  selector: 'app-enrolment',
  templateUrl: './enrolment.component.html',
  styleUrls: ['./enrolment.component.less']
})
export class EnrolmentComponent implements OnInit {
  @ViewChild('modalloader')
  private modaloader: TemplateRef<any>;
  @ViewChild('modalshowerror')
  private errorpopup: TemplateRef<any>;
  @ViewChild('startPicker') pickerStart;
  enrolmentDetails: boolean = false;
  emailpattern = "^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9-\+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$";
  enroled: any;
  member_exists: boolean = false;
  lookups: any = {};
  account_details: any = {
    user: {
      email: "",
      first_name: "",
      last_name: ""
    },
    extra_data: {
      // enrollment_code: "",
      region_text: "",
      city_text: "",
      // zipcode_text: "",
      // colleague_id: '',
      alternate_email: "",
      alternate_mobile_number: "",
      ['t&c2_marketing_opt_in']: false,
      china_cross_boarder_data_acceptance: false,
      general_personal_data_privacy: false,
      // ['t&c4_self_declaration']: false,
      ['t&c1_brilliant']: false,
      // prefer_language: "",
      pms_agent: "",
      country_code: "",
      ['brilliant_news/offers/_promotion']:null,
      ['estatement/_stay_confirmation_/_bill']:null
    },
    middle_name: "",
    date_of_birth: null,
    mobile: "",
    address_line1: "",
    address_line2: "",
    country: "",
    enrolling_sponsor: null,
    profile_id: '',
    salutation: '',
    resort: '',
    gender: null,
    nationality: null,
    enrollment_channel: "SEP_PMS",
    ffp_details: [],
    postal_code: "",
    country_dial_code: ""
  };
  countries: any;
  enrolment_hotel: string;
  states: any = null;
  cities: any = null;
  country_name: string = "";
  city_name: string = "";
  state_name: string = "";
  firstname_touched: boolean = false;
  firstname_focus: boolean = false;
  lastname_touched: boolean = false;
  lastname_focus: boolean = false;
  email_touched: boolean = false;
  email_focus: boolean = false;
  mobile_touched: boolean = false;
  mergcodemobile_touched: boolean = false;
  countryCode_touched: boolean = false;
  countryCode_focus: boolean = false;
  mobile_focus: boolean = false;
  error_msg: string = null;
  profileId: string = '0';
  resort: string = '';
  modalRef: any;
  salutations: any;
  memberData: any;
  CBPDbox : boolean = false;
  GPbox : boolean = false;
  PDbox : boolean = false;
  PAbox : boolean = false;
  allreqbox: boolean = false;
  reservation_id: string = '';
  location_address: string = '';
  verified = 'No';
  pmsAgentName: string = '';
  sponsor_name: string = '';
  form: FormGroup;
  flatPickerInput: string = '';
  startOptions: FlatpickrOptions = {
    dateFormat: 'd M Y',
    defaultDate: null,
    maxDate: new Date(Date.now()),

  };
  memberKeys: any = [];
  extraDataKeys: any = [];
  maxDate = ''; 
  hotelName = 'SHELL';
  country_mobile_code: any;
  uniqueEmailError: any;
  uniqueMobileError: any;
  CBPDError: any;
  country_dial_code_box: boolean = false;
  onlyMobileNumber : any;
  marketingBox: boolean =false;
  gDetails: any;
  constructor(
    private modalService: NgbModal, private formBuilder: FormBuilder, private memberEnrollService: MemberEnrollService, private memberService: MemberService, private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.country_mobile_code = countryCode;
    this.gDetails = JSON.parse(localStorage.getItem('gMemberDetails'));
    this.memberKeys = Object.keys(this.account_details);
    this.extraDataKeys = Object.keys(this.account_details.extra_data);
    var today = moment(new Date(), 'DD/MM/YYYY h:mm A').format("YYYY-MM-DD");
    var year = today.substring(0, 4);
    var minYear = (parseInt(today.substring(0, 4))-18).toString();
    this.maxDate = today.replace(year, minYear);
    this.form = this.formBuilder.group({
      start: null,
    });
    if(window.outerWidth<1440){
      var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
      x[0].style.zoom = (window.outerWidth/14.40).toString() + '%';
    }
    this.profileId = this.commonService.getParam('g').toString();
    this.resort = this.commonService.getParam('p').toString();
		if(this.resort === 'LHI01') {
			this.resort = 'CDHKG';
		}
		if(this.resort === 'LPNYC') {
			this.resort = 'TLNYC';
		}

    if(this.resort === 'CDHKG') {
			this.hotelName = 'Cordis, Hong Kong';
		} else if (this.resort === 'TLNYC') {
			this.hotelName = 'The Langham New York';
		}
    // this.pmsAgentName = this.commonService.getParam('u').toString().replace("%20", " ");
    if(this.pmsAgentName === '0'){
      this.pmsAgentName = '';
    }
    if(localStorage.getItem('pms_data')) {
      this.pmsAgentName = localStorage.getItem('pms_data');
    }
    // this.account_details.extra_data.colleague_id = this.pmsAgentName;
    this.account_details.profile_id = this.profileId;
    this.account_details.resort = this.resort;
    // if(localStorage.getItem('enrolment_code')!= undefined || localStorage.getItem('enrolment_code')!= null){
    //   this.account_details.extra_data.enrollment_code = localStorage.getItem('enrolment_code');
    // }
    // else{
    //   this.account_details.extra_data.enrollment_code = this.resort;
    // }
    if(localStorage.getItem('reservation_id')!= undefined || localStorage.getItem('reservation_id')!= null){
      this.reservation_id = localStorage.getItem('reservation_id');
    }
    // if(this.profileId != localStorage.getItem('profileId') || localStorage.getItem('pmsData') == undefined){
      localStorage.removeItem('pmsData');
      localStorage.removeItem('reservation_id');
      this.reservation_id = this.commonService.getParam('resv_name_id').toString();
      this.memberService
        .getPmsProfile(this.profileId, this.resort)
        .then((data: any) => {
          this.memberData = data;
          localStorage.setItem('pmsData',JSON.stringify(this.memberData));
          localStorage.setItem('profileId',this.profileId) ;
          localStorage.setItem('resort',this.resort) ;
          if(this.countries){
            this.close();
            this.countryMapping();
          }
          this.autopopulate();
        })
        .catch(error => {
          this.close();
        });
    // }
    if(localStorage.getItem('pmsData')!= undefined){
      this.memberData = JSON.parse(localStorage.getItem('pmsData'));
      if(this.memberData && this.memberData.membership_stage){
        this.account_details = this.memberData;
        // this.account_details.extra_data.colleague_id = this.pmsAgentName;
        this.account_details.profile_id = this.profileId;
        this.account_details.resort = this.resort;
        // if(localStorage.getItem('enrolment_code')!= undefined || localStorage.getItem('enrolment_code')!= null){
        //   this.account_details.extra_data.enrollment_code = localStorage.getItem('enrolment_code');
        // }
        // else{
        //   this.account_details.extra_data.enrollment_code = this.resort;
        // }
        if(this.account_details.date_of_birth && this.account_details.date_of_birth.length>0){
          this.startOptions.defaultDate = this.formatDate(this.account_details.date_of_birth, false);
        }
      }
      else{
        this.countryMapping();
        this.autopopulate();
      }
    }
    this.commonService.getData("lookups", "SALUTATION_LIST").subscribe(result => {
      this.salutations = result;
    });
    this.memberEnrollService
      .getCountires()
      .then((data: any) => {
        this.myFunction()
        if(this.memberData){
           this.close();
        }      
        this.countries = data;
        this.countries.sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.countryMapping();
      });
    this.memberEnrollService
      .getSponsorDetailsByShortName(this.resort)
      .then((data: any) => {
        for(var j=0;j<data.length;j++){
          if(data[j].extra_data.sep_property_id === this.resort){
            this.enrolment_hotel = data[j].name;
            this.location_address = data[j].address;
            this.account_details.enrolling_sponsor = data[j].id;
          }
        }
      });
      this.commonService.getData("lookups", "TIER_CLASS").subscribe(result => {
        this.lookups["TIER_CLASS"] = result;
      });
      let subscription = timer(0, 10).pipe().subscribe(x=>{
      this.open(this.modaloader,'modalloader');
      subscription.unsubscribe();
  })

  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  omit_special_char(str, key) {
    var allowed_chars = '_ +@./*&{}[()!-`"';
    var extra_msg = '';
    if(str){
      for(var i=0;i<str.length;i++){
        if(!((str[i] >= '0' && str[i] <= '9') || (str[i] >= 'a' && str[i] <= 'z') || (str[i] >= 'A' && str[i] <= 'Z') || allowed_chars.includes(str[i]))){
          if(key === 'mobile'){
            key = 'mobile_number';
          }
          if(str[i] > '~'){
            extra_msg = ". '" + str[i] + "'" + " is not an english character.";
          }
          this.error_msg = this.toTitleCase(key.replaceAll('_text', '').replaceAll('_', ' ')) + " contains " + str[i] + extra_msg;
          window.scrollTo(0, 0);
          return;
        }
      }
    }
  }

  myFunction() {
    if(window.outerWidth<1440){
      var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
      x[0].style.zoom = (window.outerWidth/14.40).toString() + '%';
    }
    else{
      var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
      x[0].style.zoom = '100%';
    }
  }

  formatDate(d, validation){
    var date = new Date(d);
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if(validation){
      return date.getFullYear() + '-' +("0" + (date.getMonth() + 1)).slice(-2)+'-'+ ("0" + date.getDate()).slice(-2);
    }
    else{
      return date.getDate()+' '+monthNames[date.getMonth()]+' '+ date.getFullYear();
    }
  }

  open(content, modalclass) {
    this.modalRef = this.modalService.open(content, { windowClass: modalclass });
  }

  close() {
    this.modalRef.close();
  }
  autopopulate() {
    this.account_details.user.first_name = this.memberData.first_name;
    this.account_details.user.last_name = this.memberData.last_name;
    if(this.memberData.middle_name){
      this.account_details.middle_name = this.memberData.middle_name;
    }
    this.account_details.user.email = this.memberData.email;
    this.account_details.date_of_birth = this.memberData.date_of_birth;
    if(this.account_details.date_of_birth && this.account_details.date_of_birth.length>0){
      this.startOptions.defaultDate = this.formatDate(this.account_details.date_of_birth, false);
    }
    if(this.memberData.ffp_details && this.memberData.ffp_details.data){
      this.account_details.ffp_details = this.memberData.ffp_details.data;
    }
    if(this.memberData.salutation && this.memberData.salutation.length>0){
      if(this.memberData.salutation[this.memberData.salutation.length-1]==='.'){
        this.memberData.salutation = this.memberData.salutation.substring(0, this.memberData.salutation.length - 1);
      }
      this.account_details.salutation = this.memberData.salutation.toUpperCase();
    }
    if(!this.memberData.mobile){
      this.memberData.mobile = '';
    }
    if(this.memberData.china_cross_boarder_data_acceptance && this.memberData.china_cross_boarder_data_acceptance==="YES"){
      this.account_details.extra_data.china_cross_boarder_data_acceptance=true;
      this.CBPDbox =true;
    }
    if( this.memberData && this.memberData['t&c1_brilliant'] && this.memberData['t&c1_brilliant']==="YES"){
        this.account_details.extra_data['t&c1_brilliant']=true;
        this.PAbox = true;
    }
    if( this.memberData && this.memberData['t&c2_marketing_opt_in'] && this.memberData['t&c2_marketing_opt_in']==="YES"){
        this.account_details.extra_data['t&c2_marketing_opt_in']=true;
        this.marketingBox=true;
    }
    this.account_details.mobile = this.memberData.mobile;
    this.account_details.address_line1 = this.memberData.address_line1;
    this.account_details.address_line2 = this.memberData.address_line2;
    // this.account_details.extra_data.address_type = this.memberData.address_type;
    let nationality = this.memberData.nationality;
    if (nationality === 'GB') {
      nationality = 'UK';

    }
    this.account_details.nationality = nationality;
    if (this.memberData.gender) {
      this.account_details.gender = this.memberData.gender.toLowerCase();
      if(this.account_details.gender !== 'male' && this.account_details.gender !== 'female'){
        this.account_details.gender = null;
      }
    }
    this.account_details.extra_data.city_text = this.memberData.city_name;
    this.account_details.extra_data.region_text = this.memberData.state_prov;
    // this.account_details.extra_data.prefer_language = this.memberData.preferred_language;

    this.account_details.extra_data.alternate_email = this.memberData.alternate_email;
    this.account_details.extra_data.alternate_mobile_number = this.memberData.alternate_mobile_number;
    this.account_details.extra_data.city_text = this.memberData.city;
    this.account_details.postal_code = this.memberData.zipcode;
    this.account_details.extra_data.region_text = this.memberData.region;
  }

  countryName: any;
  countryMapping() {
    this.memberEnrollService
    .getCountires()
    .then((data: any) => {     
      this.countries = data;
      if(this.memberData && this.memberData.country){
        for(var i=0;i<this.countries.length;i++){
          if(this.countries[i].iso_code === this.memberData.country){
            this.account_details.country = this.countries[i].id;
            this.countryName = this.countries[i].name;
          }
        }
      }
    });
  }

  firstnameFocus() {
    this.firstname_touched = true;
    this.firstname_focus = true;
    document.getElementById("firstname").focus();
  }
  firstnameFocusOut() {
    this.firstname_focus = false;
  }
  lastnameFocus() {
    this.lastname_touched = true;
    this.lastname_focus = true;
    document.getElementById("lastname").focus();
  }
  lastnameFocusOut() {
    this.lastname_focus = false;
  }
  emailFocus() {
    this.member_exists = false;
    this.email_touched = true;
    this.email_focus = true;
    document.getElementById("email").focus();
  }
  emailFocusOut() {
    this.email_focus = false;
  }

  countryCodeFocusOut() {
    this.countryCode_focus = false;
  }
  mobileFocus() {
    this.mobile_touched = true;
    this.mobile_focus = true;
    document.getElementById("mobile").focus();
  }
  mobileFocusOut() {
    this.mobile_focus = false;
  }

  validateChars() {
    for(var key in this.account_details.user){
      this.omit_special_char(this.account_details.user[key], key);
    }
    for(var key in this.account_details.extra_data){
      this.omit_special_char(this.account_details.extra_data[key], key);
    }
    if(this.error_msg){
      return false;
    }
    return true;
  }
  validate(enrollmentform) {
    // this.firstname_touched = true;
    // this.lastname_touched = true;
    // this.email_touched = true;
    // this.mobile_touched = true;
    // this.error_msg = null;
    if(this.validateChars() && enrollmentform && this.CBPDbox &&  this.GPbox && this.country_dial_code_box && this.PAbox){
      this.enroll();
    }
  }
  getCities() {
    var state = (<HTMLInputElement>document.getElementById('state')).value;
    this.account_details.region = state;
    this.account_details.city = "";
    this.open(this.modaloader,'modalloader');
    this.memberEnrollService
      .getCitiesList(state)
      .then((data: any) => {
        this.close();
        this.cities = data;
        this.cities.sort((a, b) => (a.name > b.name) ? 1 : -1);
      })
  }
  getStates() {
    var country = (<HTMLInputElement>document.getElementById('country')).value;
    this.account_details.country = country;
    return;
    this.account_details.region = "";
    this.account_details.city = "";
    this.cities= null;
    this.open(this.modaloader,'modalloader');
    this.memberEnrollService
      .getStatesList(country)
      .then((data: any) => {
        this.close();
        this.states = data;
        this.states.sort((a, b) => (a.name > b.name) ? 1 : -1);
      });
  }
  rediectToProfilePage(){
    this.resort = this.commonService.getParam('p').toString();
    var region_id = localStorage.getItem('region');
    var str = 'searchreservation?p='+this.resort;
    var url = window.location.href;
    var domain = url.split('enrolment')[0];
    window.location.href = domain + str;
  }
  enroll() {
    this.onlyMobileNumber=this.account_details.mobile;
    const fullMobileNumber = this.account_details.extra_data.country_code + this.account_details.mobile ;
    this.account_details.mobile = fullMobileNumber;
    let pms_agent = localStorage.getItem('pms_data');
    this.account_details.extra_data.pms_agent=pms_agent;
    if(this.account_details.extra_data['t&c2_marketing_opt_in'] == false) {
      this.account_details.extra_data['t&c2_marketing_opt_in'] = "NO";
    } else {
      this.account_details.extra_data['t&c2_marketing_opt_in'] = "YES"
    }

    if(this.account_details.extra_data.china_cross_boarder_data_acceptance == false) {
      this.account_details.extra_data.china_cross_boarder_data_acceptance = "NO";
    } else {
      this.account_details.extra_data.china_cross_boarder_data_acceptance = "YES"
    }

    if(this.account_details.extra_data.general_personal_data_privacy == false) {
      this.account_details.extra_data.general_personal_data_privacy = "NO";
    } else {
      this.account_details.extra_data.general_personal_data_privacy = "YES"
    }

    // if(this.account_details.extra_data['t&c4_self_declaration'] == false) {
    //   this.account_details.extra_data['t&c4_self_declaration'] = "NO";
    // } else {
    //   this.account_details.extra_data['t&c4_self_declaration'] = "YES"
    // }

    if(this.account_details.extra_data['t&c1_brilliant'] == false) {
      this.account_details.extra_data['t&c1_brilliant'] = "NO";
    } else {
      this.account_details.extra_data['t&c1_brilliant'] = "YES"
    }
    if (this.form.value.start) {
      var x= this.form.value.start[0];
      this.account_details.date_of_birth = x.getFullYear()+'-'+(x.getMonth()+1)+'-'+((x.getDate()<10)?"0"+x.getDate():x.getDate());
    }
    if(this.account_details.extra_data['t&c2_marketing_opt_in']==="YES"){
      this.account_details.extra_data['brilliant_news/offers/_promotion'] = [
        "EMAIL",
        "SMS",
        "WeChat Communication"
    ]
    }
    if(this.account_details.extra_data['t&c1_brilliant']==="YES"){
      this.account_details.extra_data['estatement/_stay_confirmation_/_bill'] = [
        "EMAIL",
        "WECHAT_COMMUNICATION"
    ]
    }
    this.error_msg = null;
    let req = {};
    if(this.account_details.membership_stage === 'FFP Member'){
      for(var it=0;it<this.memberKeys.length;it++){
        req[this.memberKeys[it]] = this.account_details[this.memberKeys[it]];
      }
      req['extra_data']={};
      for(var ix=0;ix<this.extraDataKeys.length;ix++) {
        if(this.account_details['extra_data'][this.extraDataKeys[ix]]){
          req['extra_data'][this.extraDataKeys[ix]] = this.account_details['extra_data'][this.extraDataKeys[ix]];
        }
      }
      delete req['enrolling_sponsor'];
      delete req['enrollment_channel'];
      req['membership_stage'] = 'FFP Member';
      req['member_id'] =  this.account_details.member_id;
      req['ffp_details'] = [];
    } else if(this.gDetails && this.gDetails.data.membership_stage === 'Non-member') {
      req = this.account_details;
      delete req['extra_data']['prefer_language'];
    }
    else{
      req = this.account_details;
      delete req['extra_data']['prefer_language'];
    }
    delete req['gender'];
    delete req['nationality'];
    this.open(this.modaloader,'modalloader');
    this.memberEnrollService
      .memberEnrollment(req)
      .then((data: any) => {
        this.close();
        // if (data.status === 400) {
        //   this.member_exists = true;
        // }
        if (data.status === 201 || data.status === 200) {

          let req = {
            "email": this.account_details.user.email,
          }
          this.memberEnrollService
          .ressetPassword(req)
          .then((data: any) => {
            this.close();
          })
          for(var x=0;x<this.lookups["TIER_CLASS"].length;x++){
            if(this.lookups["TIER_CLASS"][x].code === data.body.tier_class){
              data.body.tier_name = this.lookups["TIER_CLASS"][x].name;
              break;
            }
          }

          this.enroled = data.body;
          if(this.enroled.date_of_birth){
            this.enroled.date_of_birth += 'T00:00:00+00:00';
          }
          if(this.enroled.date_of_joining){
            this.enroled.date_of_joining += 'T00:00:00+00:00';
          }
          if(data.body.extra_data && data.body.extra_data['t&c']){
            this.verified = data.body.extra_data['t&c'];
          }
          for (var i = 0; i < this.countries.length; i++) {
            if (this.countries[i].id == this.enroled.country)
              this.country_name = this.countries[i].name;
          }
          if (this.states)
            for (var i = 0; i < this.states.length; i++) {
              if (this.states[i].id == this.enroled.region)
                this.state_name = this.states[i].name;
            }
          if (this.cities)
            for (var i = 0; i < this.cities.length; i++) {
              if (this.cities[i].id == this.enroled.city)
                this.city_name = this.cities[i].name;
            }
          this.enrolmentDetails = true;

        }
      })
      .catch(error => {
        this.account_details.mobile = this.onlyMobileNumber;
        this.close();
        if(error && error.error) {
          if(error.error.code === 'member_exists_unvalidated' || error.error.code === 'member_exists'){
            this.member_exists = true; 
          }
        }
        if (error && error.error){
          if (error.error && error.error.message){
            // this.error_msg = error.error.message;
            this.CBPDError = error.error.message;
            this.open(this.errorpopup,'modalshowerror');
          }

          window.scrollTo(0, 0);
          if (error.error.email) {
            this.uniqueEmailError = error.error.email;
            // this.error_msg = error.error.email[0].message;
            this.open(this.errorpopup,'modalshowerror');
          }
          if (error.error.mobile) {
            this.uniqueMobileError = error.error.mobile;
            // this.error_msg = error.error.mobile[0].message;
            this.open(this.errorpopup,'modalshowerror');
          }
        }
      })
  }

  onCountryCodeChange() {
    if(this.account_details.extra_data.country_code){
      this.country_dial_code_box = true;
    }else{
      this.country_dial_code_box = false;
    }
  }

  onToggleChange(value, id) {
    if (id == 1) {
        this.account_details.extra_data['t&c2_marketing_opt_in'] = !this.account_details.extra_data['t&c2_marketing_opt_in']; 
        if(this.account_details.extra_data['t&c2_marketing_opt_in']){
          this.marketingBox=true;
        }else{
          this.marketingBox=false;
        }
    }
    if (id == 2) {
        this.account_details.extra_data.china_cross_boarder_data_acceptance = !this.account_details.extra_data.china_cross_boarder_data_acceptance;
        if(this.account_details.extra_data.china_cross_boarder_data_acceptance===true){
          this.CBPDbox =true;
        }
        if(this.account_details.extra_data.china_cross_boarder_data_acceptance===false){
          this.CBPDbox =false;
        }
    }
    if (id == 3) {
        this.account_details.extra_data.general_personal_data_privacy = !this.account_details.extra_data.general_personal_data_privacy;
        this.GPbox = true;
        if(this.account_details.extra_data.general_personal_data_privacy===true){
          this.GPbox = true;
        }
        if(this.account_details.extra_data.general_personal_data_privacy===false){
          this.GPbox = false;
        }
    }
    // if (id == 4) {
    //     this.account_details.extra_data['t&c4_self_declaration'] = !this.account_details.extra_data['t&c4_self_declaration'];
    //     if(this.account_details.extra_data['t&c4_self_declaration']===true){
    //       this.PDbox = true;
    //     }
    //     if(this.account_details.extra_data['t&c4_self_declaration']===false){
    //       this.PDbox = false;
    //     }
    // }
    if (id == 5) {
        this.account_details.extra_data['t&c1_brilliant'] = !this.account_details.extra_data['t&c1_brilliant'];
        if(this.account_details.extra_data['t&c1_brilliant']===true){
           this.PAbox = true;
        }
        if(this.account_details.extra_data['t&c1_brilliant']===false){
          this.PAbox = false;
       }
    }
  }
}
