import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services';
import { environment } from "../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
    private host = environment.host();
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        var currentTime = new Date().getTime();
        var token = null;
        var sso_access_token =null;
        // if(localStorage.getItem('token')!=undefined){
        //     token = localStorage.getItem('token');
        // }
        if(localStorage.getItem('sso_access_token') !=undefined){
            sso_access_token =localStorage.getItem('sso_access_token');
        }

        if(request.url.includes('tenants/basic-info/')){
            request = request.clone({
                headers: new HttpHeaders({
                    'x-api-key' : environment.xapikey,
                    'Authorization':  'JWT '+ localStorage.getItem('hotel_sso_token'),
                })
            });
        }
        else if(request.url.includes('/lhg/gpms/') || request.url.includes('api.aps20.gravty') || request.url.includes('apiprod.gravty.cn')){
            token = sso_access_token;
            if(request.url.includes('/lhg/gpms/sep/properties/list') && sso_access_token != null){
                token = sso_access_token;
            }
            else if(request.url.includes('lhg/gpms/sep/sso/property/') && request.url.includes('/login') && sso_access_token != null){
                token = sso_access_token
            }
            request = request.clone({
                headers: new HttpHeaders({
                    'Authorization':  'JWT '+ token,
                    'x-api-key' : environment.xapikey,
                    'Cache-control': "private",
                })
            });
        } 
        else
        {
            request = request.clone({
                headers: new HttpHeaders({
                    'Authorization':  'JWT '+ sso_access_token,
                })
            });
        }
        if(request.url == this.host + 'oauth2/token') {
            request = request.clone({
                headers: new HttpHeaders({
                    'Authorization':  'JWT '+ sso_access_token,
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'x-api-key' : environment.xapikey,
                    'Cache-control': "private",
                })
            });
        }
        if(request.url.includes('/sep/reservation/')){
            request = request.clone({
                headers: new HttpHeaders({
                    'Authorization':  'JWT '+  localStorage.getItem('hotel_sso_token') ,
                    'x-api-key' : environment.xapikey,
                    'Cache-control': "private"
                })
            });
        }
        if(request.url == this.host + 'sep/folio/settle-with-points'){
            request = request.clone({
                headers: new HttpHeaders({
                    'Authorization':  'JWT '+ localStorage.getItem('hotel_sso_token'),
                    'x-api-key' : environment.xapikey,
                    'Cache-control': "private"
                })
            });
        }
        return next.handle(request);
    }
}