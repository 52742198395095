import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.less']
})
export class ErrorPopupComponent implements OnInit {

  @Input() uniqueEmailError: any;
  @Input() uniqueMobileError: any;
  @Input() CBPDError: any;
  errMsg: any;
  rewardconsumed : boolean = false;
  constructor(
		private modalService: NgbModal
	) { }

  ngOnInit() {
    this.errMsg = "";
    if(this.uniqueEmailError){
      if(this.uniqueEmailError && this.uniqueEmailError[0].code == 'unique') {
        this.errMsg = 'Email ID already exist!';
      } else {
        this.errMsg = this.uniqueEmailError[0].message;
      }
    }
   else if(this.uniqueMobileError){
    if(this.uniqueMobileError && this.uniqueMobileError[0].code == 'unique') {
      this.errMsg = 'Mobile No. already exist! Please try again';
    } else {
      this.errMsg = this.uniqueEmailError[0].message;
    }
   }
   else {
    this.errMsg=this.CBPDError;
   }

  }
  close() {
		this.modalService.dismissAll();
	}

}
