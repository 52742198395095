import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-member-preferance',
  templateUrl: './member-preferance.component.html',
  styleUrls: ['./member-preferance.component.less']
})
export class MemberPreferanceComponent implements OnInit {

  @Input() memberData: any;
  pillowPreference: any;
  foodPreference: any;
  eStatementPreference: any;
  constructor(		private modalService: NgbModal) { }

  ngOnInit() {
    this.pillowPreference = this.memberData.extra_data["pillow_preference/_pillow_type"];
    this.foodPreference = this.memberData.extra_data["food/snacks"];
    this.eStatementPreference = this.memberData.extra_data["estatement/_stay_confirmation_/_bill"];
  }
  close() {
		this.modalService.dismissAll();
	}
}
