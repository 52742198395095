import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "./../environments/environment";
import { User } from '../models';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    private host = environment.host();

    public get currentUserValue(): User {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        return this.currentUserSubject.value;
    }

    // login(request: any) {
    //     return this.http.post<any>(this.host+"login/", request)
    //         .pipe(map(user => {
    //             // login successful if there's a jwt token in the response
    //             if (user && user.token) {
    //                 // store user details and jwt token in local storage to keep user logged in between page refreshes
    //                 user.header = true;
    //                 localStorage.setItem('currentUser', JSON.stringify(user));
    //                 this.currentUserSubject.next(user);
    //             }
    //             return user;
    //         }));
    // }

    logout() {
        // remove user from local storage to log user out
        //localStorage.removeItem('currentUser');
        localStorage.clear();
        this.currentUserSubject.next(null);
        // if (saveexit) {
        //     this.router.navigate(['/profile']);
        // } else {
        //     this.router.navigate(['/profile']);
        // }
    }

}