import { Injectable } from "@angular/core";
import { WebService } from "../services/web.service";
import { environment } from "./../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
    private webService: WebService,
  ) { }
  private host = environment.host();
  private gravtyhost = environment.gravtyhost();
  private privilegeshost = environment.privilegeshost();
  private gravityhosttemp = environment.gravityhosttemp();
  hotelSSOlogindetails:any;
  getGMemberDetails(memberId) {
    let requestUrl = this.gravtyhost + "members/data/" + memberId + "/";
    return this.webService.getAPI(requestUrl, false);
  }
  getMemberData(profileId, resortId) {
    var tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    let requestUrl = this.host + "sep/gravty-member/?resort=" + resortId + "&profile=" + profileId;
    return this.webService.getAPI(requestUrl, false);
  }
  getPmsProfile(profileId, resortId) {
    var tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    let requestUrl = this.host + "sep/pms-profile/?resort=" + resortId + "&profile=" + profileId;
    return this.webService.getAPI(requestUrl, false);
  }
  getFrequentFlyer() {
    let requestUrl = this.gravtyhost + "entity-data/members/frequentflyer_programlist/";
    return this.webService.getAPI(requestUrl, false);
  }
  ffp(request) {
    let requestUrl = this.host + "ffp/";
    return this.webService.postAPI(requestUrl,request,false);
  }
  setFrequentFlyer(request) {
    let requestUrl = this.gravtyhost + "entity-data/members/frequentflyer_program/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  updateFrequentFlyer(request) {
    let requestUrl = this.gravtyhost + "entity-data/members/frequentflyer_program/" + request.id + "/";
    return this.webService.putAPI(requestUrl, request, false);
  }
  deleteFrequentFlyer(request) {
    let requestUrl = this.gravtyhost + "entity-data/members/frequentflyer_program/" + request.id + "/";
    return this.webService.deleteAPI(requestUrl, false);
  }

  getInvoiceDetails(resv_id,resort) {
    let requestUrl = this.host + "sep/reservation/folios/?confirmation_number=" + resv_id + "&property_id=" + resort;
    return this.webService.getAPI(requestUrl, false);
  }

  getPointsConversionList() {
    let requestUrl = this.gravtyhost + "point-conversion-rates/list/";
    return this.webService.getAPI(requestUrl, false);
  }
  createSimulationBit (request){
    var  requestUrl = this.gravityhosttemp + 'bitsimulation/';
    return this.webService.postAPI(requestUrl, request, false);
  }

  createBit(request) {
    let requestUrl = this.gravtyhost + "bit/";
    return this.webService.postAPI(requestUrl, request, false);
  }

  reservationTracesSync(request){
    let requestUrl = this.host + "sep-ui/reservation/traces/sync";
    return this.webService.postAPI(requestUrl, request, false);
  }
  
  getSponsorLocations(id) {
    let requestUrl = this.gravtyhost + 'sponsors/' + id +'/locations/?active=True&page_size=1000';
    return this.webService.getAPI(requestUrl, false);
  }

  payWithPoints(request) {
    let requestUrl = this.host + "sep/folio/settle-with-points";
    return this.webService.postAPI(requestUrl, request, false);
  }

  getAwardOffers(memberId) {
    let requestUrl = this.gravtyhost + "members/offers/"+memberId+"/?offerType=award&ordering=-created_ts";
    return this.webService.getAPI(requestUrl, false);
  }

  getAllSponsorLocations() {
    let requestUrl = this.gravtyhost + 'locations/';
    return this.webService.getAPI(requestUrl, false);
  }

  getfilterPrivilegesCode(memberId,prevCode){
    let requestUrl = this.privilegeshost + "members/" + memberId + '/privileges/?privilege_code='+ prevCode;
    return this.webService.getAPI(requestUrl, false);
  }

  getPrivileges( memberId) {
    let requestUrl = this.privilegeshost + "members/" + memberId + '/privileges?page_size=100';
    return this.webService.getAPI(requestUrl, false);
  }
  resendInvitation(request) {
    let requestUrl = this.gravtyhost + "members/send-otp/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  getBitCategoriesList = function(){
    var requestUrl = this.gravtyhost +'bits/categories/?screen_name=bit_listing';
    return this.webService.getAPI(requestUrl, false);
  };
  getSponsorsList = function(){
    var requestUrl = this.gravtyhost +'trim/sponsors/?page_size=300&status=A,P,Q';
    return this.webService.getAPI(requestUrl, false);
  };
  getBitData = function(member_id, bitcatgeory, bittype, sponsorid, startdate, enddate, cursor){
    var requestUrl = this.gravtyhost +'bits/?member_id=' + member_id;
    if ((bittype && bittype !== '' && bittype !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?bit_type=' + bittype);
      } else {
          requestUrl += ('&bit_type=' + bittype);
      }
    }
    if(bitcatgeory === '' || !bitcatgeory){
      bitcatgeory = 'ACCRUAL,REDEMPTION';
    }
    if ((bitcatgeory && bitcatgeory !== '' && bitcatgeory !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?bit_category=' + bitcatgeory);
      } else {
          requestUrl += ('&bit_category=' + bitcatgeory);
      }

    }
    if ((sponsorid && sponsorid !== '' && sponsorid !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?sponsor_id=' + sponsorid);
      } else {
          requestUrl += ('&sponsor_id=' + sponsorid);
      }

    }
    if ((startdate && startdate !== '' && startdate !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?date_from=' + startdate);
      } else {
          requestUrl += ('&date_from=' + startdate);
      }

    }
    if ((enddate && enddate !== '' && enddate !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?date_to=' + enddate);
      } else {
          requestUrl += ('&date_to=' + enddate);
      }

    }
    if(cursor){
      requestUrl = cursor;
    }
    return this.webService.getAPI(requestUrl, true);
  };
  getLoayltyAccounts = function(){
    var requestUrl = this.gravtyhost + 'loyaltyaccounts/';
    return this.webService.getAPI(requestUrl, false);
  };

  getListOfHotelsForSSO(region){
    var requestUrl = environment.apiendpoint.url + 'v1/lhg/gpms/sep/properties/list/?region=' + region;
    return this.webService.getAPI(requestUrl, false);
  }

  loginToParticularHotel(hotel,region){
    let hotelId = hotel.property_id;
    var requestUrl =  environment.apiendpoint.url +  'v1/lhg/gpms/sep/sso/property/' + hotelId + '/login/?region='+region;
    return this.webService.getAPI(requestUrl,false);
  }
  
  exportMemberBITData = function(memberId, bitcatgeory, bittype, sponsorid, startdate, enddate) {
    var requestUrl = this.gravtyhost + 'bit/export/?member_id=' + memberId;
    if ((bittype && bittype !== '' && bittype !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?bit_type=' + bittype);
      } else {
          requestUrl += ('&bit_type=' + bittype);
      }
    }
    if(bitcatgeory === '' || !bitcatgeory){
      bitcatgeory = 'ACCRUAL,REDEMPTION';
    }
    if ((bitcatgeory && bitcatgeory !== '' && bitcatgeory !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?bit_category=' + bitcatgeory);
      } else {
          requestUrl += ('&bit_category=' + bitcatgeory);
      }

    }
    if ((sponsorid && sponsorid !== '' && sponsorid !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?sponsor_id=' + sponsorid);
      } else {
          requestUrl += ('&sponsor_id=' + sponsorid);
      }

    }
    if ((startdate && startdate !== '' && startdate !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?date_from=' + startdate);
      } else {
          requestUrl += ('&date_from=' + startdate);
      }

    }
    if ((enddate && enddate !== '' && enddate !== null)) {
      if (requestUrl.indexOf("?") === -1) {
          requestUrl += ('?date_to=' + enddate);
      } else {
          requestUrl += ('&date_to=' + enddate);
      }

    }
    return this.webService.getAPI(requestUrl, false);
  };
}
