// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let url = window.location.href;
let pid = '7';
let uytr = 'GRAVTY@1234';
let uname = 'gpms.user@lji.io';
let uatAPIKeyfirst :string  = 'rFGDxqrSaC4KxzuZJI5';
let uatAPIKeysecond :string  = 'KQ12BL6nVcaRY7ybG12Ej';
export const environment = {
	production: false,
	apiendpoint:{
		//url:'https://api.aps20.gravty.io/',
		url:'https://apiuat.gravty.cn/',
		version:'v1/',
		endpoint:'lhg/gpms/'
	},
	privilegesEndPoint:{
		//url:'https://api.aps20.gravty.io/',
		url:'https://apiuat.gravty.cn/',
		version:'v1/',
		endpoint:'lhg/gpms/sep/ui/v3/'
	},
	gravtyendpoint:{
		//url:'https://api.aps20.gravty.io/',
		url:'https://apiuat.gravty.cn/',
		version:'v1/',
		endpoint:'lhg/gpms/sep/ui/v1/'
	},
	h_program_id : pid,
	username: uname,
	password: uytr,
	xapikey : uatAPIKeyfirst + uatAPIKeysecond,
	host : function () {
		return this.apiendpoint.url + this.apiendpoint.version + this.apiendpoint.endpoint;
	},
	gravtyhost : function () {
		return this.gravtyendpoint.url  + this.gravtyendpoint.version + this.gravtyendpoint.endpoint;
	},
	gravityhosttemp : function () {
		return this.gravtyendpoint.url  + this.gravtyendpoint.version + this.gravtyendpoint.endpoint;
	},
	privilegeshost : function () {
		return this.privilegesEndPoint.url  + this.privilegesEndPoint.version + this.privilegesEndPoint.endpoint;
	}
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
